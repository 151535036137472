@tailwind base;
@tailwind components;
@tailwind utilities;

@import 'theme/default';
@import 'theme/color';
@import 'theme/dynamicVariable';
@import 'font/font';
@import 'forms/forms';
@import'components/spinner';
@import'components/text';
@import'components/popup';
@import'components/accordion';
@import'components/table';
@import'components/slider';
@import'components/buttons';
@import'components/tabsStyle';
@import'components/links.scss';
@import'components/monoScreenControl';
@import'components/animations';
@import'components/reactCropUI';
@import'components/mixins';
@import'components/commonStyles';