.icon-wrapper {
  @media screen and (max-width:550px){
    &.border-wrap {
      border: 1px solid var(--cu-black-100);
      border-radius: 0.375rem;
      padding: 0.5625rem;
      aspect-ratio: 1;
      justify-content: center;
      align-items: center;
      min-width: 2.1rem;
    }
  }
  &.toggle{
    circle{
      &:hover{
        fill:var(--cu-black-10);
      }
    }
  }
  &.fill-color {
    &.activeIcon {
      svg {
        path {
          fill: var(--cu-white-200);
        }
      }
    }
    @media screen and (min-width:550px){
    &:hover{
        svg {
            path {
              fill: var(--cu-white-200);
            }
          }  
    }
  }
  }
  &.first-fill-color {
    &.activeIcon {
      svg {
        path:first-child {
          fill: var(--cu-white-200);
        }
      }
    }
    @media screen and (min-width:550px){
    &:hover{
        svg {
            path:first-child {
              fill: var(--cu-white-200);
            }
          }  
    }
  }
  }
  &.stroke-color {
    &.activeIcon {
      svg {
        path {
          stroke: var(--cu-white-200);
        }
      }
    }
    @media screen and (min-width:550px){
    &:not(.on-hover){
      &:hover{
        svg {
            path {
                stroke: var(--cu-white-200);
            }
          }  
    }
  }
  }
  }
  &.circle-fill-color {
    &.activeIcon {
      svg {
        circle {
          fill: var(--cu-white-200);
        }
      }
    }
    @media screen and (min-width:550px){
    &:hover{
        svg {
            circle {
                fill: var(--cu-white-200);
            }
          }  
    }
  }
  }
  &.rect-stroke-color {
    &.activeIcon {
      svg {
        rect {
          stroke: var(--cu-white-200);
        }
      }
    }
    @media screen and (min-width:550px){
    &:hover{
        svg {
            rect {
              stroke: var(--cu-white-200);
            }
          }  
        }
    }
  }
  &.rect-fill-color {
    &.activeIcon {
      svg {
        rect {
          fill: var(--cu-white-200);
        }
      }
    }
    @media screen and (min-width:550px){
    &:hover{
        svg {
            rect {
              fill: var(--cu-white-200);
            }
          }  
        }
    }
  }

  &.display-lists{
    &:hover{
      svg {
          rect {
            fill: var(--cu-black-5);
          }
        }  
  }
    &.active{
      svg {
        rect {
          fill:var(--cu-black-5);
          stroke: var(--cu-white-200);
        }
      }  
    }
  }
  &.disabled{
    cursor: no-drop;
  }

  &.fill-color-2 {
    &.activeIcon {
      svg {
        path:last-child {
          fill: var(--cu-white-200);
          stroke: var(--cu-white-200);
        }
      }
    }

    @media screen and (min-width:550px) {
      &:hover {
        svg {
          path:last-child {
            fill: var(--cu-white-200);
            stroke: var(--cu-white-200);
          }
        }
      }
    }
  }
}
