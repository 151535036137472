@import '../Styles/main.scss';

.App {
  text-align: center;
  gap: 10px;
  color: var(--cu-white-200);
  overflow: hidden;
  .border-rad-10 {
    border-radius: 10px;
  }
  &.is-ios{
    @media (display-mode: fullscreen) {    
    padding-bottom: 1.9rem;
    }
  }
.grey-border{
  border: 1px solid var(--cu-black-100);
}
  .border-y-0 {
    border-bottom: 0;
    border-top: 0;
  }

  .font-10 {
    font-size: .625rem;
  }

  .font-12 {
    font-size: .75rem;
    line-height: 0.875rem;
  }
  .font-13 {
    font-size: 0.8125rem;
    line-height: 0.875rem;
  }

  h4 {
    font-size: 14px
  }

  .font-14 {
    font-size: 0.875rem;
    line-height: 1.0625rem;
  }

  .error-validation {
    font-size: .625rem;
    color: var(--cu-red-550);
    line-height: 1rem;
    text-align: left;
  }

  ::-webkit-scrollbar {
    width: 0.25rem;
    height: 0.25rem;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: transparent;
    width: 2.5rem;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: var(--cu-grey-650);
    border: 3px solid transparent;
    border-radius: 6px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;

  }

  .loader-bg {
    z-index: 100;
    width: 100%;
    background: rgb(0, 0, 0, 67%);
    height: 100%;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;

    span {
      width: 5rem;
      height: 2rem;
    }
  }

  .auth-avatar {
    border-radius: 50%;
    border: 0.125rem solid rgb(255 255 255 / 20%);
    padding: 0.88rem;
    width: 4.375rem;
  }

  @media screen and (min-width: 551px) and (max-width: 1024px) {
    svg {
      width: 1.75rem;
      height: 1.75rem;
    }
  }
}

.dangerously-innerHTML-container {
  h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, small, strong, sub, sup, li, figure, figcaption, hr {
    margin: 0 !important;
    padding: 0 !important;
    border: 0 !important;
    font-size: 100%;
    font-family: inherit;
    vertical-align: baseline;
  }

  /* Headings */
  h1, h2, h3, h4, h5, h6 {
    font-weight: bold;
  }

  h1, h2, h3, h4, h5, h6, p {
    color: var(--cu-white-200);
  }
  
  /* Heading1 */
  h1 {
    font-size: 2.25em;
  }
  /* Heading2 */
  h2 {
    font-size: 1.5em;
  }
  /* Heading3*/
  h3 {
    font-size: 1.25em;
  }
  /* Heading4 */
  h4 {
    font-size: 1em;
  }
  /* Heading5 */
  h5 {
    font-size: 0.75em;
  }
  /* Heading6 */
  h6 {
    font-size: 0.5em;
  }
  
  /* Paragraphs */
  p {
    margin: 1em 0;
    font-size: 0.9375rem;
    font-size: 1rem;
  }
  
  /* Lists */
  ul, ol {
    margin: 0;
    padding: 0 0 0 2em; 
    font-size: 100%;
    font-family: inherit;
    vertical-align: baseline;
    li {
      margin:  0;
      padding: 0;
      display: list-item;
      text-align: -webkit-match-parent;
    }
  }
  ul li {
    list-style: disc;
  }
  ol li {
    list-style: decimal;
  }


  /* Blockquote */
  blockquote {
    margin: 1em 0;
    padding: 0.5em 1em;
    border-left: 2px solid #ccc;
  }
  
  /* Preformatted text */
  pre {
    margin: 1em 0;
    padding: 0.5em;
    background-color: #f8f8f8;
    overflow: auto;
    font-family: monospace;
  }
  
  /* Horizontal rule */
  hr {
    border: 1px solid #ccc;
    margin: 1em 0;
  }
  a {
    color: var(--cu-blue-200); 
    text-decoration: underline;
    cursor: pointer; 
    transition: all 0.3s;
    opacity: 0.9;
    &:hover {
      opacity: 1;
      text-decoration: none; 
    }
  }
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 5vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  color: white;
}

.App-link {
  color: #61dafb;
}

.utilIcons {
  width: .7rem;
  cursor: pointer;
}

.icons {
  width: 1rem;
  cursor: pointer;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
@keyframes scaleUp-animation {
  from {
    transform: scale(0);
    }
    
    to {
    transform: scale(1);
  }
}
.scaleUp-effect-1 {
  animation: scaleUp-animation 0.25s ease-in-out;
}

.posts-skeleton-wrap{
  margin-top: 1rem;
  .details-skeleton{
    grid-template-columns: auto;
    gap: 0.5rem;
    .skeleton-wrapper {
      height: 5rem;
      aspect-ratio: initial;
  }
  }
}
.event-skeleton-wrap{
  .details-skeleton{
    grid-template-columns: repeat(auto-fill, minmax(17rem, 1fr));
  }
}

.btn-style{
  border: none;
  border-radius: 50%;
  border: 1px solid var(--cu-black-100);
  color: var(--cu-white-900);
  font-size: .75rem;
  padding: 0.65rem 1rem;
  transition: all .3s;
&.save{
    background: var(--cu-black-500);
}
&:hover {
  background: var(--dark-mode-300);
}
&.active{
  border: 1px solid var(--cu-white-900);
}
}

.new-btn_style_001, .new-btn_style_002 {
  transition: all 0.3s;
  color: var(--cu-white-900);
  border: none;
  box-shadow: 0 0 0 1px var(--cu-black-100);
  &:hover {
    background: var(--dark-mode-300);
  }
}
.new-btn_style_002 {
  background: var(--cu-black-200);
}

.Toastify__toast-body > div:last-child {
  padding: 0 10px 0 0;
}

//for web3Onboard dialog
:root {
  --onboard-link-color: #FFFFFF;
  --onboard-warning-100: var(--dark-mode-300);
  --onboard-warning-400: var(--dark-mode-300);
  --onboard-warning-700: #FFFFFF;
  --onboard-modal-backdrop: transparent;    
  --onboard-success-100: #CCCCCC;
  --onboard-success-200: #BBBBBB;
  --onboard-success-300: #AAAAAA;
  --onboard-success-400: #999999;
  --onboard-success-500: #888888;
  --onboard-success-600: #777777;
  --onboard-success-700: #666666;
}

.common-tooltip-with-arrow-tip {
  transition: all 0.3s;

  &::after {
    pointer-events: none;
    content: attr(data-text);
    min-width: 9rem;
    white-space: pre-wrap;
    height: auto;
    background: var(--cu-black-700);
    position: absolute;
    top: 2rem;
    right: -4rem;
    font-size: 0.75rem;
    padding: 0.625rem;
    opacity: 0;
    border-radius: var(--normal-border-radius);
    transition: opacity 0.3s ease;
  }

  &::before {
    content: "";
    position: absolute;
    pointer-events: none;
    top: 0rem;
    right: 8px;
    opacity: 0;
    transition: opacity 0.3s ease;
    border-style: solid;
    border-width: 27px 6px 6px;
    border-color: transparent transparent var(--cu-black-700) transparent;
  }

  &:hover {

    &::after,
    &::before {
      opacity: 1;
      z-index: 20;
    }
  }
}

.dot-separator{
  position: relative;
  @media screen and (max-width: 550px) {
    margin-left: 0rem;
    padding-left: 0.8125rem;
  }
  &::before{
    content: "";
    position: absolute;
    width: 0.25rem;
    height: 0.25rem;
    left: -0.875rem;
    top: 45%;
    border-radius: var(--normal-border-radius);
    background: var(--cu-black-5);
    @media screen and (max-width: 550px) {
      left: 0rem;
      top: 42%;
    }
  }
}
.nft-price{
  font-size: 0.875rem;
  color:var(--cu-black-5);
  font-weight: 500;
  @media screen and (max-width: 550px) {
    font-size: 0.875rem;
  }
}

.clickable-links{
  color:var(--cu-black-5) !important;
  &:hover{
    color:var(--cu-white-200) !important;
  }
}

.non-highlight-color{
  color:var(--cu-black-5);
}


.container-wrapper {
  transition: all 0.5s ease-in-out;
  &:hover {
    .hover-community {
      //transition: all 0.5s ease-in-out;
      opacity: 1 !important;
      height: 100% !important;
      width: 100% !important;
      :is(.collection-img, p, .details-item, .item-count) {
        scale: 1 !important;
      }
    }
  }
}

@keyframes comeToDisplay {
  0% {
    opacity: 0;
  }
  80% {
    opacity: 0;
  }
  90% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
.visibleSlowly {
  animation: comeToDisplay 0.6s ease-out;
}
@keyframes slowAppear-1 {
  0% {
    opacity: 0;
  }
  98% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.slowAppearStyle {
  animation: slowAppear-1 0.85s ease-out;
}
.split-row{
  display: grid !important;
  grid-template-columns: repeat(2, 1fr) !important;
  column-gap: var(--cards-gap) !important; 
  @media screen and (max-width: 550px) {
    grid-template-columns: repeat(1, 1fr) !important;
    row-gap: var(--cards-gap);
  }
}

.sub-content-section{
    .custom-image-container{
      width: 18dvw;
    }
}
.gap-4-mob-gap-6{
  gap:1rem;
  @media screen and (max-width: 550px) {
    gap:1.625rem;
  }
}

.full-screen-popup-on-sm{
  @media screen and (max-width: 550px) {
    height: 100% !important;
    max-height: 100% !important;
    margin: 0rem !important;
  }
}

.hide-on-mobile{
  display: flex;
  @media screen and (max-width: 550px) {
    display: none !important;
  }
}

.black-n-white{
  background: var(--cu-black-101) !important;
}
.hide-overflow-on-mobile{
  @media screen and (max-width: 550px) {
    overflow: hidden !important;
  }
}
.scroll-with-long-content{
  @media screen and (max-width: 550px) {
    overflow-y: auto;
    max-height: calc(100dvh - 3rem) !important;
  }
}
.character-icon-text {
  @media screen and (max-width: 550px) {
      font-family: 'Noto Sans', sans-serif;
  }
}

.cur8-logo{
  width: 1.25rem;
  aspect-ratio: 1;
  border-radius: 1rem;
  border: 1px solid var(--cu-black-100);
}

.text-wrapper{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.icon-wrapper-bg{
  background: var(--cu-black-500);
  padding: 0.375rem;
  border-radius: 2rem;
}
.icon-wrapper-bg-2{
  background: var(--cu-black-500);
  padding: 0.3rem;
  border-radius: 2rem;
}
.drag-start{    
  border: 0.0625rem dashed var(--cu-white-900) !important;
}

.create-gallery-btn_home {
  position: relative;
  display: flex;
  gap: 1rem;
  align-items: center;
  .warning-toolkit{
    min-width: 23rem;
    top: -0.5rem;
    .warning-wrap {
      position: relative;
      p{
        position: static;
      }
        &.arrow-left {
            p {
                &::after {
                    left: -0.8125rem;
                    top: 0.7rem;
                }

                &::before {
                    left: -0.5625rem;
                    top: 0.75rem;
                }
            }
        }
    }
}
  .create-gallery-btn_home-text {
      font-size: 1.25rem;
      font-weight: 500;
      margin-bottom: 3px;
      .cgb_title {
          display: block;
      }
  }
  .cgb_plus-icon {
      display: flex;
      align-items: center;
      margin-left: auto;
  }
}
.scroll-from-parent{
  .infinite-scroll-component{
    overflow: unset !important;
  }
}
.my-collection-tile-view {
  .skeltonMainClass {
      top: 2rem;
  }
}

.tooltip-w-auto{
  .tooltip-message-wrapper{
    .tooltip-message{
      min-width: auto !important;
    }
  }
}

  div[style*="position: fixed"][style*="inset: 0px"] {
    z-index: 1000; 
  }

.common-bg-color{
  background: var(--cu-black-400);
}
.common-cards-style{
  gap: var(--cards-gap);
}

.mobile-filter-icons-wrapper {
  @media screen and (max-width:550px){
  border: 1px solid var(--cu-black-100);
  border-radius: 0.35rem;
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
  height: 2rem;
  }
}
.copy-right{
  font-size: 0.75rem;
  color: var(--cu-white-100);
  justify-content: center; 
  margin-bottom: 1rem;
}

.single-line-text{
  -webkit-line-clamp: 1;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  white-space: normal;
  word-break: break-all;
}
.line-height-normal{
  line-height: normal;
}

.tab-desc {
  text-align: start;
  font-size: 0.875rem;
  color: var(--cu-black-5);
  word-break: break-word;
  white-space: normal;
  line-height: normal;
  text-transform: initial;
}

.subject-label{
  display: flex;
  justify-content: center;
  align-items: center;
  padding:0.25rem 0.5rem;
  border-radius:0.75rem;
  background: var(--cu-black-800);
  color: var(--cu-white-100);
  font-size: 0.8rem;
}

.grid-cols-3{
  grid-template-columns: repeat(3, minmax(0, 1fr));
}

.navbar-btn{
  border-radius: var(--border-radius-lg);
  padding: 0.575rem 0.75rem !important;
  color:var(--cu-black-5);
  font-size: 1rem !important;
  line-height: normal !important;
  &:is(:hover,&.active){
    color:var(--cu-white-200);    
  }
}

.under-drop-down-tabs{
  &.coming-soon{
    @media screen and (max-width: 550px) {
        min-height: calc(100dvh - (var(--navbar-height)) - 4rem);
    }   
}
}

.blockchain-logo-img{
  width: 1.2rem;
  height: 1.2rem;
  flex: 0 0 1.2rem;
}

.text-link{
color: var(--cu-black-5);
font-size: 1rem;
font-style: normal;
font-weight: 400;
line-height: normal;
}

.filter-wrapper{
  @media screen and (max-width: 550px) {
    padding: 0rem 1rem 0rem 0rem !important;
}   
}

.gallery-details-wrap{
  @media screen and (max-width: 550px) {
    padding-left: 0rem;
    padding-right: 0rem;
}  
}

.overflow-unset-imp{
overflow: unset !important;
}
.collection-wrapper{
  .react-loading-skeleton{
    border-radius: var(--border-radius-lg);
  }
}

.flag-btn{
  &.small-button .small-button-container{
    padding: 0.667rem 0.75rem !important;
    @media screen and (max-width: 550px) {
      padding: 0.3125rem 0.5rem !important;
  }  
   }
}

.hide-on-mob{
  @media screen and (max-width: 550px) {
   display: none !important;
}  
}