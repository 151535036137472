.spinner{
    position: fixed;
    display: flex;
    @apply inset-0;
    align-items: center;
    justify-content: center;
    background: var(--cu-black-trans-500);
    -webkit-backdrop-filter: blur(2px);
    backdrop-filter: blur(5px);
    z-index: 1002;
    @media screen and (min-width: 767px) { 
        left: 0; //--right-menu-width
        top: 0; //--top-menu-height
        transition: all 0.75s;
        transition-delay: 0s;
    }
}