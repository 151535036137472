:root {
    --collection-card-height: 170px;
    --collection-enlarge-card-height: 170px;
    --collection-enlarge-layout-height: 170px;
    --collection-added-card-height: 170px;
    --collection-enlarge-layout-width: 11rem;
    --collection-gallery-previewcard-height: 170px;
    --frame-card-height: auto;
    --gallery-min-height: auto;
    --dicover-collection-ht: auto;
    --discover-collection-card-height: 15.25rem;
    --right-menu-width: 0px;
    --top-menu-height: 0px;
    --home-fcollection-height: 0px;
    --main-page-height: 100vh;
    --selected-gallery-height:auto;
    --skelton-count:5;
    --collection-sm-card-height:50px;
    --collection-card-lg-sm-height: 170px;
    --skeleton-height:0px;
    --skeleton-count:0;
    --music-player-zIndex: 1001;
    --proof-of-craft-popup-zIndex: 1000;
    --window-width: 100vw;
    --window-height: 100vh;
    --mobile-footer-zIndex: 10;
    --mobile-header-zIndex: 100;
    --page-loader-zIndex: 101;
    --preview-gallery-controls-height-in-mobile: calc(var(--window-height) / 2.285);
    --content-card-banner-img-aspect-ratio:16 / 3.484;
    --account-content-card-banner-img-aspect-ratio:16 / 3;
    --content-card-gallery-aspect-ratio:16 / 9;
    --content-card-profile-img-width:5.125rem;
    --content-card-profile-img-mob-width:3.75rem;
    --navbar-height:4.75rem;
    --mob-navbar-up-down-padding:0.25rem;
    --normal-border-radius:0.375rem;
    --cards-gap:2rem 1rem;
    --heading-description-gap:0.5rem;
    --text-content-gap:1.5rem;
    --contents-gap:4rem;
    --event-card-count-in-minimized-area:3;
    --event-card-count-percentage-in-minimized-area:30%;
    --event-card-count-percentage-with-sidebar:22%;
    --event-card-count-percentage:17%;
    --event-card-count-percentage-tab:21%;
    --event-card-count-with-sidebar:4;
    --event-card-count:5;
    --collections-card-count-percentage-in-minimized-area:22%;
    --gallery-creation-assets-count-in-minimized-area:3;
    --gallery-creation-assets-count-percentage-in-minimized-area:25%;
    --border-radius-lg:0.75rem;
    --events-img-aspect-ratio:2/1.2;
    --scrollable-cards-width:88dvw;
    --details-content-gap: 2.5rem;
    --details-section-buttons-border-radius:0.75rem;
    --details-section-buttons-border-radius-mob: 0.375rem;
    --content-top-gap-from-tabs:2rem;
    --content-top-mini-gap-from-tabs:1rem;
    --details-content-remaining-gap-value:1rem;
    --event-card-popup-height:52.8125rem;
    --long-tabs-border-radius:0.625rem;
    --vertical-tab-wrapper-width:20rem;
    --timeline-card-height:40dvh;
    --gap-between-line-and-timeline-card: 1.5rem;
}