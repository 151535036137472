body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #14171F;
}
.hide-twise-agent{
  #vanessa-cvfk3j {
    display: none !important;
  }
}
@media screen and (max-width: 550px) {
  html, body {
    overscroll-behavior: contain;
    touch-action: manipulation;
    -webkit-text-size-adjust: 100%;
    text-size-adjust: 100%;
  }
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.Toastify__toast {
  background-color: #14171F !important;
  color: #FFFFFF !important; 
  border-radius: .75rem !important; 
  font-size: 16px; 
  border: 1px solid var(--dark-mode-300, #30333A);
  padding-right: 1.75rem;
}
.Toastify__toast-body {
  color: #D0D5DD;
  display: flex !important;
  text-align: left !important;
  white-space: pre-line;
}


.Toastify__close-button {
  position: absolute;
  top: 50%;
  right: 8px; 
  transform: translateY(-50%);
  color: #D0D5DD !important;
  background: transparent !important; 
  outline: none; 
}
.loader-wrapper{
  inset: 0;
  padding: 2rem 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background: black;
  z-index: 10000;
  position: fixed;
}
.loader-wrapper #iframeloader{
  width: 12dvw;
  height: auto;
}
@media screen and (max-width: 550px) {
  .loader-wrapper #iframeloader {
    width: 25%;
  }
}