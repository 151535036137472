.common_style-tab-container {
    .tab-data {
        display: flex;
        gap: 1.4rem;
        border-bottom: 1px solid var(--cu-black-200);
        box-sizing: border-box;
        cursor: pointer;
        color: var(--cu-black-5);
        width: 100%;
        .tab {
            font-size: .875rem;
            line-height: 1.21429rem;
            padding-bottom: .5rem;
            box-sizing: border-box;
            position: relative;
            display: flex;
            gap: 0.25rem;
            border-bottom: solid 2px transparent;

            &.not-active {
                &::after {
                    background: none repeat scroll 0 0 transparent;
                    bottom: -1px;
                    content: "";
                    display: block;
                    height: 1px;
                    left: 50%;
                    position: absolute;
                    background: var(--cu-white-200);
                    transition: width 0.3s ease 0s, left 0.3s ease 0s;
                    width: 0;
                }

                &:hover {
                    color: var(--cu-white-200);

                    &::after {
                        width: 100%;
                        left: 0;
                    }
                }
            }

            &.active {
                color: var(--cu-white-200);
                border-bottom: solid 2px var(--cu-white-200);

            }
        }

    }
    @media screen and (max-width: 550px) {

        &.tab-end-gap-iOS {
            // for Safari iOS
            @supports (-webkit-touch-callout: none) {
                width: calc(100% - 1rem);
            }
        }

        .tab-data {
            overflow-x: auto;
            gap: 1rem;
            -ms-overflow-style: none;
            /* Internet Explorer 10+ */
            scrollbar-width: none;

            /* Firefox */
            &::-webkit-scrollbar {
                display: none;
                /* Safari and Chrome */
            }

            .tab {
                white-space: nowrap;

                &:last-child {
                    margin-right: 1.5rem;
                }
            }
        }
    }
}

