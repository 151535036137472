.mono-screen {
    &.screen-frame-type-10 {               
        --frame-border-width: calc(max(var(--gallery-height),var(--gallery-width)) * 0.03px);                    
        border-width: calc(var(--frame-border-width)/2);                           
        background-color: var(--image-color-background);                    
        border-radius:2px;
        border-bottom-color: var(--image-color-border-2);
        border-left-color: var(--image-color-border-3);  
        border-right-color: var(--image-color-border-3);  
        border-top-color: var(--image-color-border-1);

        &::after {
            content: '';
            display: block;                
            position: absolute;
            top: calc(var(--frame-border-width)/2);   
            height: calc(100% - var(--frame-border-width));             
            left: calc(var(--frame-border-width)/2);
            width: calc(100% - var(--frame-border-width));                
            box-shadow: 0 1px 8px 0 rgba(0,0,0,0.5) inset;
            z-index:1;
        }

        &.no-detail-frame .nft-frame-container {
            .nftframe {                 
                border: solid 1px;
                border-bottom-color: #dddddd55;
                border-left-color: #cdcdcd55;
                border-right-color: #cdcdcd55;
                border-top-color: #bbbbbb55;                    
            }
        }

        .frame-detail-1 {
            border-radius: 1px;
            inset: calc(var(--frame-border-width)/4 - 2px);
            box-shadow: 0 2px 5px 0 rgba(0, 0, 0, .25) inset;                    
            position: absolute;  
        }

        .frame-detail-2 {
            border-radius: 2px;
            inset: calc(var(--frame-border-width)/4 + 2px);
            box-shadow: 0 2px 5px 0 rgba(0, 0, 0, .25) inset;                    
            position: absolute;  
        }
    }
    
    .mordern-gallery-slider, .card-layout-container {
        background-color: var(--image-color-background);
        padding: var(--image-size-padding);
    }

    &.screen-frame-type-texture {                                   
        --frame-margin: calc(max(var(--gallery-height),var(--gallery-width)) * var(--frame-thickness));
        margin: calc(var(--frame-margin) / 2);        
        height: calc(100% - var(--frame-margin));
        width: calc(100% - var(--frame-margin));                                           

        &::before {
            content: '';
            display: block;                
            position: absolute;
            background-image: var(--screen-frame-texture);
            --gallery-landscape-ratio: max(0,calc(1 - (var(--gallery-height) / var(--gallery-width))));
            left: calc(0% - var(--frame-margin) * var(--gallery-landscape-ratio));
            width: calc(100% + (var(--frame-margin) * var(--gallery-landscape-ratio))*2);
            --gallery-portrait-ratio: (max(0,calc(1 - (var(--gallery-width) / var(--gallery-height)))));
            top: calc(0% - var(--frame-margin) * var(--gallery-portrait-ratio));
            height: calc(100% + (var(--frame-margin) * var(--gallery-portrait-ratio))*2);                
            background-repeat: round;             
        }

        &::after {
            content: '';
            display: block;                
            position: absolute;
            top: calc(var(--frame-margin)/2);   
            height: calc(100% - var(--frame-margin));             
            left: calc(var(--frame-margin)/2);
            width: calc(100% - var(--frame-margin));                
            box-shadow: 0 2px 8px 0 rgba(0,0,0,0.5) inset;
            z-index: 1;
            border-radius: var(--normal-border-radius);
        }        

        .mordern-gallery-slider {
            border-radius: var(--normal-border-radius);
        }

        &.ful-scr .nft-frame-container {
            .nftframe {                                     
                background: var(--image-color-background);
            }
        }            
        &.no-detail-frame .nft-frame-container {
            .nftframe {                                 
                &::after {
                    content: '';
                    display: block;                
                    box-shadow: 0 0.5px 5px 0 rgba(0, 0, 0, 0.2) inset;
                    width: 100%;
                    height: 100%;
                    position: absolute;
                }
                
            }
        }            

        &.ful-scr {
            &:not(.screen-frame-type-0)
            {
                .nft-img-sec {
                    border-radius: var(--normal-border-radius);
                }
            }            
        }
    }        
    &.screen-frame-type-20 {
        --frame-border-width: calc(max(var(--gallery-height),var(--gallery-width)) * 0.03px);                    
        border-width: calc(var(--frame-border-width)/2);                                   
        background-color: var(--image-color-background);                    
        border-radius:2px;
        border-bottom-color: var(--image-color-border-1);
        border-left-color: var(--image-color-border-1);  
        border-right-color: var(--image-color-border-1);  
        border-top-color: var(--image-color-border-1);

        &::after {
            content: '';
            display: block;                
            position: absolute;
            top: calc(var(--frame-border-width)/2);   
            height: calc(100% - var(--frame-border-width));             
            left: calc(var(--frame-border-width)/2);
            width: calc(100% - var(--frame-border-width));             
            box-shadow: 0 1px 8px 0 rgba(0,0,0,0.5) inset;
            z-index: 1;
        }

        &.no-detail-frame .nft-frame-container {
            .nftframe {                 
                border: solid 2px;
                border-bottom-color: #dddddd55;
                border-left-color: #cdcdcd55;
                border-right-color: #cdcdcd55;
                border-top-color: #bbbbbb55;                    
            }
        }

        .frame-detail-1,.frame-detail-2,.frame-detail-3,.frame-detail-4 {
            border-radius: 1px;
            border-image: linear-gradient(80deg, var(--image-color-border-1), var(--image-color-border-2) 50%, var(--image-color-border-1)) 1;                 
            position: absolute;  
            border-width: 1px;                 
        }

        .frame-detail-1 {
            inset: calc(var(--frame-border-width)*0.1);
        }

        .frame-detail-2 {                
            inset: calc(var(--frame-border-width)*0.2); 
        }

        .frame-detail-3 {                
            inset: calc(var(--frame-border-width)*0.3);
        }

        .frame-detail-4 {                
            inset: calc(var(--frame-border-width)*0.4);
        }
    }
    &.screen-frame-type-30 {
        --frame-border-width: calc(max(var(--gallery-height),var(--gallery-width)) * 0.05px);                    
        border-width: calc(var(--frame-border-width)/2);
        background-color: var(--image-color-border-1);                    
        border-radius:2px;
        border-bottom-color: var(--image-color-border-2);
        border-left-color: var(--image-color-border-2);  
        border-right-color: var(--image-color-border-2);  
        border-top-color: var(--image-color-border-2);

        &::after {
            content: '';
            display: block;                
            position: absolute;
            top: calc(var(--frame-border-width)/2);   
            height: calc(100% - var(--frame-border-width));             
            left: calc(var(--frame-border-width)/2);
            width: calc(100% - var(--frame-border-width));                
            box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 0.5em inset, rgba(0, 0, 0, 0.1) 0.1em 0px 0.1em inset, rgba(0, 0, 0, 0.05) -0.1em 0px 0.1em inset, rgb(255, 255, 255) 0px 0.3em 0.2em;
        }
        .nft-frame-container {
            .nftframe {                 
                border: solid calc(var(--frame-border-width)/2);
                border-left-width: calc(var(--frame-border-width)/2 * var(--border-aspect-ratio));
                border-right-width: calc(var(--frame-border-width)/2 * var(--border-aspect-ratio));                                        
                position: relative;

                &:before {
                    content: ' ';
                    display: block;
                    position: absolute;                    
                    top: calc(-var(--frame-border-width)/2);   
                    height: calc(100% + var(--frame-border-width));             
                    left: calc(-var(--frame-border-width)/2);
                    width: calc(100% + var(--frame-border-width));  
                    z-index: -1;
                    background: #fcfcfc;
                    box-shadow: rgba(0, 0, 0, 0.25) 0px 2px 0.5em inset, rgba(0, 0, 0, 0.1) 0.01em 0px 0.1em inset, rgba(0, 0, 0, 0.05) -0.01em 0px 0.1em inset, rgba(255, 255, 255, 0.1) 0px 0.1em 0.2em;
                }

                >div {
                    //border: solid 0.1rem;                                                                
                    border: solid 2px;
                    border-bottom-color: #dddddd77;
                    border-left-color: #cdcdcd77;
                    border-right-color: #cdcdcd77;
                    border-top-color: #bbbbbb77;
                    
                }
            }
        }
    }

    &.mono-view {
        &.ful-scr {
            .nftframe {
                height: 100% !important;
                width: 100% !important;
                .nft-img-sec {
                    object-fit: cover;
                    width: 100%;
                    height: 100%;
                }
            }
        } 
    }    
}