.curator-type-wrapper {
    // margin-left: auto;
    color: var(--cu-white-900);
    background: var(--cu-black-400);
    padding: 0.25rem 0.75rem;
    border-radius: 10px;
    font-size: 0.875rem;
    @media screen and (max-width:550px){
        font-size: 0.8125rem;
    }
}

.collection-card-proofOfCraftIcon {
    svg {
        width: 1rem;
        height: 1rem;
    }
}

.svg-icon_with-no-hover-effect {
    &:hover {
        cursor: auto !important;
        svg {
            path {
                fill: var(--cu-black-5) !important;
            }

            circle {
                fill: var(--cu-black-5) !important;
            }

            rect {
                stroke: var(--cu-black-5) !important;
            }

        }
    }
}

.creator-title_wrapper {
    display: flex;
    align-items: center;
    gap: 0.35rem;
    color: var(--cu-black-5);
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;

    .creator-by-text_style {
        font-style: italic;
        color: var(--cu-black-5);
        font-size: 1rem;
    }

    @media screen and (max-width: 550px) {
        font-size: 0.875rem;
        .creator-by-text_style {
            font-size: 0.75rem;
        }
    }
}

.common-side-filter_name-popup-style {
    position: fixed;
    z-index: 4;
    transform: translate(1.5rem, -1.5rem);
    display: inline-block;
    padding: 0.75rem 1rem;
    color: var(--cu-white-200);
    background-color: var(--cu-black-400);
    border-radius: 0.5rem;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.75);
    transition: opacity 0.2s ease;
}