#react-crop-ui-root {
    position: fixed;
    z-index: calc(var(--mobile-footer-zIndex) + var(--mobile-header-zIndex) +  var(--music-player-zIndex));
    .react-image-crop-overlay {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        display: flex;
        gap: 2rem;
        align-items: center;
        justify-content: center;
        background: rgb(0 0 0 / 56%);
        backdrop-filter: blur(1px);
        z-index: calc(var(--mobile-footer-zIndex) + var(--mobile-header-zIndex) +  var(--music-player-zIndex));

        .react-image-crop-box {
            width: auto;
            height: auto;
            max-width: 60vw;
            max-height: 95vh;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: 3rem;
            background: var(--cu-black-700);
            border-radius: 12px;
            padding: 1rem;
            box-shadow: 0 0 0 1px var(--cu-black-40);

            .inline-crop-wrapper {
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 4rem;

                .crop-preview-container,
                .react-crop-container {
                    width: 50%;

                    .label {
                        text-align: center;
                        font-size: 0.875rem;
                        color: var(--cu-white-700);
                        display: block;
                        height: 2rem;
                    }

                    .react-crop {
                        max-width: 400px;
                        max-height: 400px;
                    }
                }

                .react-crop-container {
                    max-height: 80vh;
                    overflow-x: hidden;
                    overflow-y: auto;
                }

            }

            .actions-wrapper {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 2rem;

                button {
                    width: 25%;
                    border-radius: 50px;
                    padding: 0.5rem 1rem;
                }
            }
        }

    }

    @media screen and (max-width: 550px) {
        .react-image-crop-overlay {
            .react-image-crop-box {
                width: 100dvw;
                max-width: 100dvw;
                height: 100dvh;
                max-height: 100dvh;
                justify-content: space-between;
                gap: 2rem 2rem;
                padding: 2rem 1rem;
                border-radius: 0;
                box-shadow: none;
                overflow: auto;

                .inline-crop-wrapper {
                    flex-direction: column;
                    justify-content: space-evenly;
                    height: auto;

                    .crop-preview-container {
                        height: 30dvh;
                        width: 100%;
                    }
                    
                    .react-crop-container {
                        width: 100%;
                        height: auto;
                        max-height: 100%;
                        overflow-x: unset;
                        overflow-y: unset;
                        .react-crop {
                            max-height: 100%;
                        }
                    }
                }
            }
        }
    }
}