.page-title-sm{
    font-size: .875rem;
    padding-left: .625rem;
    line-height: 1rem;
}

.see-all-style{
font-size: 1rem;
}

.clamp_ellipsis {
    display: -webkit-box;
    overflow: hidden;
    -webkit-box-orient: vertical;
    white-space: normal;
}

.common_tooltip-text-style {
    font-size: 0.8125rem !important;
}

.common_main-header-text-style {
    text-align: left;
    font-size: 1.5rem;
    text-transform: capitalize;
    
    @media screen and (max-width: 550px) {
        font-size: 1.25rem;
    }
}