// Normal scale up animation
@keyframes normalScaleUpEffectKF {
    0% {
        transform: scale(0);
    }
    100% {
        transform: scale(1);
    }
}
.normalScaleUpEffect {
    animation: normalScaleUpEffectKF 0.25s linear;
}

@keyframes mobile_gallery_controls_icons_open {
    0% {
        transform: scale(0);
    }
    50% {
        transform: scale(0.5);
    }
    50% {
        transform: scale(1.2);
    }
    100% {
        transform: scale(1);
    }
}
.mobile_gc_icons_open1 {
    animation: mobile_gallery_controls_icons_open 0.2s linear;
}
.mobile_gc_icons_open2 {
    animation: mobile_gallery_controls_icons_open 0.3s linear;
}
.mobile_gc_icons_open3 {
    animation: mobile_gallery_controls_icons_open 0.4s linear;
}
.mobile_gc_icons_open4 {
    animation: mobile_gallery_controls_icons_open 0.5s linear;
}
.mobile_gc_icons_open5 {
    animation: mobile_gallery_controls_icons_open 0.6s linear;
}

@keyframes inventory-button-animation {
    0% {
        transform: scale(0.9);
    }
    50% {
        transform: scale(1.2);
    }
    100% {
        transform: scale(1);
    }
}

.inventory-button-click-effect {
    animation: inventory-button-animation 0.25s linear;
}
