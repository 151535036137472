.info-slider-nav {
    height: 95dvh;
    overflow-y: auto;
    padding: 0 1rem;
    .slick-track{
        display: flex;
        gap: 0.5rem;
    }
    .title {
        color: var(--cu-white-200);
        font-size: clamp(0.625rem,0.875rem,1rem);
        text-align: left;
        -webkit-line-clamp: 2;
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        white-space: normal;
    }

    .slick-next,
    .slick-prev {
        &::before {
            color: var(--cu-white-200) !important;
        }
    }
}

.info-slider-nav-mob-container {
    width: 100%;
    height: 60dvh;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 0 1rem 2.5rem 0;

    .info-slider-nav-mob {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        gap: 1rem;

        .title {
            width: 100%;
            font-size: 1rem;
            text-align: start;
        }
    }
}