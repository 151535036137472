.social-icons {
    padding: 0.535rem;
    border-radius: var(--normal-border-radius);
    cursor: pointer;
    @media screen and (max-width:550px){
        padding: 0.3125rem 0.375rem;
    }
    &.share-icon{
        border: 1px solid var(--cu-black-100);
        padding: 0.57rem;
        border-radius: var(--details-section-buttons-border-radius);
        @media screen and (max-width:550px){
            padding: 0.25rem 0.35rem;
            border-radius: var(--details-section-buttons-border-radius-mob);
        }
        &:hover{
            border: 1px solid transparent;
        }
    }
    &:hover {
        background: var(--cu-black-50);
        .stroke-color {
            svg {
                path {
                    stroke: var(--cu-white-200);
                }
            }
        }
    }
}

// for Link tag
.hyperlink-effect {
    transition: all 0.25s ease;
    :hover {
      cursor: pointer;
      .hyper-text {
          text-decoration: underline;
      }
    }
}
.hyperlink-inactive {
    pointer-events: none;
}