.infobackBtn {
    background: linear-gradient(var(--cu-black-500) 0 0) padding-box, linear-gradient(to bottom, var(--cu-blue-300), var(--cu-blue-700)) border-box;
    color: var(--cu-white-900);
    padding: 0.3125rem;
    border: .0625rem solid transparent;
    border-radius: 0.625rem;
    display: inline-block;
    font-style: normal;
    font-weight: 500;
    cursor: pointer;
    position: relative;
    margin-top: auto;
    font-size: 0.5625rem;

    @screen md {
        margin-top: 0;
        font-size: 0.875rem;
        line-height: 1.0625rem;
        padding: .625rem
    }
}

.viewButtons {
    gap: 0.1875rem;

    img {
        filter: grayscale(0.7) brightness(1.3);
        -webkit-filter: grayscale(0.7) brightness(1.3);
        transition: opacity .3s ease;
        height: 1.1rem;

        &.activeDis {
            filter: none;
            -webkit-filter: none;
        }
    }

    @media screen and (max-width: 550px) {
        .list {
            display: none;
        }
    }
}

.action-small-container {
    border-radius: 0.375rem;
    border: 1px solid var(--cu-black-100);
    font-size: 0.75rem !important;
    line-height: 1.21429rem; 
    padding: 0.375rem 0.875rem;
    cursor: pointer;
    color: var(--cu-white-200);
    transition: all 0.3s;
    &:hover {
        color: var(--cu-white-900);
        background: var(--dark-mode-300);
    }
}

.mobile_controls_close_btn {
    display: none;
    
    transition: all 0.3s;
    color: var(--cu-white-200);
    border: none;
    padding: 0.75rem 0.875rem;
    border-radius: 0.85rem;
    box-shadow: 0 0 0 1px var(--cu-black-100);
    font-size: 0.875rem;
    z-index: 2;
    
    &:hover {
      background: var(--dark-mode-300);
      color: var(--cu-white-900);
    }
    @media screen and (max-width: 550px) {
        display: block;
    }
}

.icon-as-button {
    border-radius: var(--details-section-buttons-border-radius);
    border: 1px solid var(--cu-black-100);
    padding: 0.667rem;
    &:hover {
        background: var(--cu-black-50);
    }
    @media screen and (min-width: 551px) and (max-width: 1024px) {
        padding: 0.45rem;
    }
    @media screen and (max-width:550px){
        padding: 0.4rem;
        border-radius: var(--details-section-buttons-border-radius-mob);
    }
}